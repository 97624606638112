import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Box, Typography, useMediaQuery } from '@mui/material'
import propTypes from 'prop-types'
import { questionsBSelectorGeneric } from '../../../utils/selectors'
import { BUILDER_CDN } from '../../../constants/endpoints'
import SelectMultiple from '../../quiz/inputs/select-multiple'
import QuestionGrid from '../../quiz/question/question-grid'
import { setAnswer } from '../../../actions/quiz.actions'
import { trackEvent } from '../../../utils/analytics'
import useActiveCampaign from '../../../hooks/use-active-campaign'
import { useHistory, useLocation } from 'react-router-dom'
import { QUESTIONNAIRE_ID_SKIN_PASSWORDLESS } from '../../../constants/configs/questionnaire'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import { Image } from 'components/builder/blocks/shared/Image'
import { getQuestionnaireInfo } from '../../../utils/questionnaire-configurator'

const DATA_CY_PREFIX = 'proven-quiz-first-question-container'
const ctaName = 'Get My Formula'

const getPageEventName = pathname => {
  let pageEventName = null
  if (pathname && pathname[0] === '/') {
    pageEventName = pathname.substring(1)
  } else {
    pageEventName = pathname
  }
  return pageEventName
}

/**
 * @param nextStep
 * @param question
 * @returns {JSX.Element}
 * @constructor
 */
function ProvenQuizFirstQuestion({
  imageBackgroundDesktopPath = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2Fbdb7d2dfcd214c5c9de84d2d1f915017`,
  imageBackgroundMobilePath = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69%2Fbdb7d2dfcd214c5c9de84d2d1f915017`,
  title = 'Feel the Difference of Personal Skincare',
  subtitle = 'What are your main skin concerns?',
  trackEventName = 'homepage_cta',
  variant,
  questionnaireId
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const campaign = useActiveCampaign()
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const questions_b = useSelector(state => questionsBSelectorGeneric(questionnaireId)(state))

  const [answersSelected, setAnswersSelected] = useState([])
  const [processing, setProcessing] = useState(false)

  const pageEventName = getPageEventName(pathname)

  const isSKinQuiz = !questionnaireId || questionnaireId === QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
  const concernQuestion = getQuestionnaireInfo(
    questionnaireId || QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
  ).firstQuestionCached

  const onContinue = (e, ctaName) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setProcessing(true)
    nextStep(ctaName)
    setProcessing(false)
  }

  const isOtherSelectedInQuestions = () => {
    const answers = answersSelected[concernQuestion._id]
    const otherSelected = answers && answers?.indexOf(7)
    return otherSelected >= 0
  }

  const isNoneSelectedAnswer = () => {
    const answers = answersSelected[concernQuestion._id]
    return answers?.length === 0
  }

  const updateFirstAnswer = () => {
    const qId = question?.question?._id
    const answers = answersSelected[concernQuestion._id]
    dispatch(setAnswer({ [qId]: answers }))
  }
  const getBaseQuizUrl = () => {
    const uri = getQuestionnaireInfo(
      questionnaireId || QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
    ).questionnaireUri
    return uri.endsWith('/') ? uri.slice(0, -1) : uri
  }

  const nextStep = ctaName => {
    const eventName = trackEventName

    const segEvnt = {
      CTA: ctaName,
      variant: variant
    }

    if (pageEventName) {
      segEvnt['landing_version'] = pageEventName
    }
    let url = `${getBaseQuizUrl()}/name`
    if (isNoneSelectedAnswer()) {
      trackEvent(eventName, segEvnt)
      url = isSKinQuiz ? '/quiz/concern' : getBaseQuizUrl()
    } else {
      updateFirstAnswer()
      trackEvent(eventName, segEvnt)
      if (isOtherSelectedInQuestions()) {
        url = `${getBaseQuizUrl()}/concern_text`
      }
    }
    if (campaign?.promoCode) {
      url = url + '?promo=' + campaign?.promoCode
    }
    history.push(url)
  }

  // do not render this component if there are no questions loaded
  if (!questions_b || questions_b?.length === 0) {
    return null
  }

  // Get first question
  const question = questions_b[0]

  return (
    <Box
      data-cy={`${DATA_CY_PREFIX}-section`}
      display="grid"
      sx={{
        width: ['100%', '100%', '100%', '49%'],
        height: ['unset', 'unset', 'unset', '805px'],
        columnGap: 2,
        gridTemplateColumns: '1fr',
        py: 12,
        minHeight: '100vh',
        position: 'relative',
        [onDesktop]: {
          pt: 9.3,
          py: 0,
          mt: 15,
          mb: 15.875
        }
      }}
    >
      <Box
        data-testid="proven-quiz-first-question-text-container-testid"
        sx={{
          textAlign: 'center',
          [onDesktop]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
            zIndex: 1
          }
        }}
      >
        {!isLgUp && (
          <Box
            data-testid="proven-quiz-first-question-title-container-mobile-testid"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: [4, 4, 2, 4],
              mt: [0, 0, 0, 4]
            }}
          >
            <Typography
              data-testid="proven-quiz-first-question-title-mobile-testid"
              variant="h2"
              textAlign="center"
              sx={{
                maxWidth: ['unset', '540px', '540px', 'unset']
              }}
            >
              {title}
            </Typography>
          </Box>
        )}
        {!isLgUp && (
          <Box
            data-testid="proven-quiz-first-question-image-background-mobile-testid"
            sx={{ position: 'relative', width: { xs: 'unset', md: 655 }, mb: 4, mx: 'auto' }}
          >
            <Image
              lazy
              image={imageBackgroundMobilePath}
              aspectRatio={0.8848}
              sx={{
                borderRadius: 1,
                [onDesktop]: {
                  borderRadius: 2
                }
              }}
            />
          </Box>
        )}
        {isLgUp && (
          <Typography
            data-testid="proven-quiz-first-question-title-desktop-testid"
            variant="h2"
            sx={{
              [onDesktop]: {
                mb: 2,
                maxWidth: 545
              }
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          data-testid="proven-quiz-first-question-subtitle-container-testid"
          sx={{
            mb: 4.25,
            textAlign: ['center', 'center', 'center', 'left'],
            [onDesktop]: {}
          }}
        >
          <Typography
            data-testid="proven-quiz-first-question-subtitle-testid"
            variant="title2"
            color={'gray.elysian'}
          >
            {subtitle}
          </Typography>
        </Box>
        {isLgUp ? (
          <Box
            data-testid="proven-quiz-first-question-questions-container-desktop-testid"
            sx={{
              mb: 5,
              [onDesktop]: {
                my: 8
              }
            }}
          >
            <SelectMultiple
              _id={question._id}
              items={question.question.items}
              setAnswer={setAnswersSelected}
              answer={answersSelected[question._id]}
              style={'light-green'}
            />
          </Box>
        ) : (
          <Box
            data-testid="proven-quiz-first-question-questions-container-mobile-testid"
            sx={{
              mt: 4,
              mb: [6, 6, 4]
            }}
          >
            <SelectMultiple
              _id={question._id}
              items={question.question.items}
              setAnswer={setAnswersSelected}
              answer={answersSelected[question._id]}
              style={'light-green'}
            />
          </Box>
        )}
        <QuestionGrid isMultiColumn>
          <Button
            variant="contained"
            color="secondary"
            onClick={e => onContinue(e, ctaName)}
            disabled={processing}
            sx={{
              width: ['140px', '140px', '230px', '100%'],
              mx: 'auto'
            }}
          >
            CONTINUE
          </Button>
        </QuestionGrid>
      </Box>

      {isLgUp && (
        <Box
          data-testid="proven-quiz-first-question-image-background-desktop-testid"
          sx={{
            display: 'flex',
            justifyContent: 'end',
            position: 'absolute',
            py: 0,
            top: 0,
            right: '-105%',
            width: 805,
            [onDesktop]: {
              top: '50%',
              transform: 'translate(0, -50%)'
            }
          }}
        >
          <Image
            lazy
            image={imageBackgroundDesktopPath}
            aspectRatio={1}
            sx={{
              width: 655,
              borderRadius: 2,
              left: 'auto',
              right: 0
            }}
          />
        </Box>
      )}
    </Box>
  )
}

ProvenQuizFirstQuestion.propTypes = {
  imageBackgroundDesktopPath: propTypes.string,
  imageBackgroundMobilePath: propTypes.string,
  title: propTypes.string,
  subtitle: propTypes.string
}

export default ProvenQuizFirstQuestion
